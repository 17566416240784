<template>
  <div class="typeWrap main">
    <div class="columnWrap mb20">
      <i class="el-icon-arrow-left mr15 cursorP" @click="jumpTiku"> </i>
      <p class="mr10">{{ crumbsname }}</p>
      <div @click="crumbsClick()">
        <i class="iconfont icon-change"></i> 切换
      </div>
    </div>
    <div class="contentWrap">
      <div class="bottomLeftWrap">
        <ul>
          <li
            v-for="(item, index) in leftMenuList"
            :key="index"
            :class="nowActive == item.name ? 'on' : ''"
            @click="changeLeftMenu(item)"
          >
            <div>
              <i class="iconfont" :class="item.icon"></i>
              {{ item.name }}
            </div>
            <i class="el-icon-arrow-right"></i>
          </li>
        </ul>
      </div>
      <div class="bottomRightWrap">
        <router-view />
      </div>
    </div>
    <praticeHomekemu ref="praticeHomekemu" />
  </div>
</template>

<script>
import praticeHomekemu from "../tiku/compnents/praticeHomekemu.vue";
export default {
  components: {
    praticeHomekemu,
  },
  data() {
    return {
      crumbsname: "",
      leftMenuList: [
        {
          id: 1,
          name: "我的错题",
          icon: "icon-error",
          path: "/error_question",
        },
        {
          id: 2,
          name: "试题收藏",
          icon: "icon-collectti",
          path: "/collect_question",
        },
        {
          id: 3,
          name: "试卷收藏",
          icon: "icon-collectjuan",
          path: "/collect_paper",
        },
        {
          id: 4,
          name: "练习历史",
          icon: "icon-history",
          path: "/test_history",
        },
      ],
      nowActive: "",
    };
  },
  mounted() {
    if (sessionStorage.getItem("str_column") == null) {
      this.$refs.praticeHomekemu.centerDialogVisible1 = true;
    } else {
      this.$refs.praticeHomekemu.centerDialogVisible1 = false;
      this.crumbsname = sessionStorage.getItem("str_column");
    }
    this.nowActive = this.$route.meta.name;
  },
  methods: {
    jumpTiku() {
      this.$router.push("/tiku");
    },
    crumbsClick() {
      this.$refs.praticeHomekemu.centerDialogVisible1 = true;
    },
    changeLeftMenu(item) {
      this.nowActive = item.name;
      this.$router.push({
        path: item.path,
        meta: {
          name: item.name,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.typeWrap {
  padding-top: 20px;
  box-sizing: border-box;
  .cursorP {
    cursor: pointer;
  }
  .columnWrap {
    font-size: 16px;
    display: flex;
    align-items: center;
    i {
      font-size: 20px;
    }
    div {
      width: 64px;
      height: 26px;
      line-height: 26px;
      font-size: 14px;
      border-radius: 13px;
      border: 1px solid var(--change-color);
      color: var(--change-color);
      text-align: center;
      cursor: pointer;
      i {
        font-size: 12px;
      }
    }
  }
  .contentWrap {
    width: 100%;
    display: flex;
    background: #fff;
    border-radius: 8px;
    .bottomLeftWrap {
      width: 220px;
      padding: 15px 0;
      box-sizing: border-box;
      border-right: 1px solid #e9eaeb;
      li {
        height: 48px;
        line-height: 48px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        padding: 0 24px;
        div {
          display: flex;
          align-items: center;
          i {
            margin-right: 14px;
            font-size: 24px;
          }
        }
        &.on {
          color: var(--change-color);
        }
      }
    }
    .bottomRightWrap {
      width: calc(100% - 221px);
    }
  }
}
</style>
